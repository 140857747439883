import React from 'react'
import PropTypes from 'prop-types'
import {graphql, StaticQuery} from 'gatsby'
import {Col} from 'reactstrap'
import TagCard from './TagCard'

class BodySide extends React.Component {
  render() {
    const {data, lang} = this.props
    const tags = data.allTagsYaml.edges[0].node.main;
    const tagNames = data.allTagsYaml.edges[0].node.data.reduce((obj, tag) => {
      obj[tag.tag] = tag.name
      return obj
    }, {})
    const groups = data.tagPages.group.reduce((obj, group) => {
      obj[group.fieldValue] = group
      return obj
    }, {})

    console.debug(data, tags, tagNames, groups)

    return (
        <Col md="4" className="d-none d-md-block" id="body-side">
          {tags.map((tag, i) => (
              groups[tag] ? <TagCard key={i} tag={tag} name={tagNames[tag]} edges={groups[tag].edges}/> : null
          ))}
        </Col>
    )
  }
}

BodySide.propTypes = {
  data: PropTypes.shape({
    tagPages: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allTagsYaml: PropTypes.shape({
      edges: PropTypes.array,
    })
  }),
};

export default () => (
    <StaticQuery
        query={graphql`
query BodySideQuery {
  allTagsYaml {
    edges {
      node {
        main
        data {
          tag
          name
        }
      }
    }
  }
  tagPages: allMarkdownRemark(
    sort: {fields: [frontmatter___index], order: ASC},
    filter: {frontmatter: {templateKey: { eq: "article-page" }, invisible:{ne: true}}}) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            index
            title
          }
        }
      }
    }
  }
}
    `}
        render={(data) => <BodySide data={data}/>}
    />
)

