import React from 'react'
import PropTypes from 'prop-types'
import {Col, Container, Jumbotron, Row} from "reactstrap";
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import BodySide from '../components/BodySide'
import BodyMain from '../components/BodyMain'

export const IndexPageTemplate = ({
                                    image,
                                    title,
                                    heading,
                                    subheading,
                                    category,
                                    lang,
                                    data
                                  }) => (
  <div>
    <Row>
      <Col>
        <Jumbotron fluid
                     style={{
                       backgroundImage: `url(${
                         !!image.childImageSharp ? image.childImageSharp.fluid.src : image
                         })`,
                       backgroundPosition: `center center`,
                       backgroundAttachment: `fixed`,
                     }}
          >
          <Container fluid className="text-white jumbotronInner">
            <h1 className="display-5">{title}</h1>
            <p className="lead">
              {heading}
            </p>
          </Container>
        </Jumbotron>
      </Col>
    </Row>
    <Row>
      <BodySide data={data} lang={lang} />
      <BodyMain data={data} category={category} lang={lang} />
    </Row>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string
}

class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.updateDimensions);
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions()
  }

  updateDimensions() {
    var ua = navigator.userAgent.toLowerCase();
    var isApp = ua.indexOf('anbai') > -1
    let element = document.getElementById('main-wrapper');
    if (isApp) {
      element.classList.add('is-app');
    } else {
      element.classList.remove('is-app');
    }
    // const width = window.innerWidth
    // if (width < 1170) {
    //   element.setAttribute('data-sidebartype', 'mini-sidebar');
    //   element.classList.add('mini-sidebar');
    // } else {
    //   element.setAttribute(
    //     'data-sidebartype',
    //     'full'
    //   );
    //   element.classList.remove('mini-sidebar');
    // }
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.updateDimensions);
    window.removeEventListener('resize', this.updateDimensions);
  }

  render() {
    const {data} = this.props
    const {frontmatter} = data.markdownRemark
    const alldata = this.props.data
    return (
      <Layout lang={frontmatter.lang}>
        <IndexPageTemplate
          image={frontmatter.image}
          title={frontmatter.title}
          heading={frontmatter.heading}
          subheading={frontmatter.subheading}
          category={frontmatter.category}
          lang={frontmatter.lang}
          data={alldata}
        />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    categoryPages: PropTypes.shape({
      edges: PropTypes.array,
    }),
    tagPages: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allCategoriesYaml: PropTypes.shape({
      edges: PropTypes.array,
    })
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        subheading
        image
        category
        lang
      }
    }
    allCategoriesYaml(filter: {data: {elemMatch: {lang: {eq: "ja"}}}}) {
      edges {
        node {
          data {
            category
            name
            icon
          }
        }
      }
    }
    categoryPages: allMarkdownRemark(
      sort: {fields: [frontmatter___index], order: ASC},
      filter: {frontmatter: {templateKey: { eq: "article-page" }, invisible:{ne: true}, lang:{ne: "en"}}}) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              index
              title
            }
          }
        }
      }
    }
  }    
`