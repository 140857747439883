import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import {Card, ListGroup, ListGroupItem} from 'reactstrap'

class CategoryCard extends React.Component {
  render() {
    const {edges, category, name, icon} = this.props


    return (
      <Card className="categoryCard">
        <h3 className="my-4 mx-2 text-center"><i className={icon} /> {' '} {name}</h3>
        <ListGroup flush>
          {edges &&
          edges.map(({node}, i) => (
            i >= 4 ? null :  <ListGroupItem key={i}>
              <Link to={node.fields.slug}>
                {node.frontmatter.title}
              </Link>
            </ListGroupItem>
          ))}
          <ListGroupItem className="text-center">
            <Link to={'/category/' + category}>
              {category.startsWith('en') ? 'View all pages' : 'すべて見る'}
            </Link>
          </ListGroupItem>
        </ListGroup>
      </Card>
    )
  }
}

CategoryCard.propTypes = {
  category: PropTypes.string,
  name: PropTypes.string,
  icon: PropTypes.string,
  edges: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  })),
}

export default CategoryCard