import React from 'react'
import PropTypes from 'prop-types'
import {graphql, StaticQuery} from 'gatsby'
import {Col, Container, Row} from 'reactstrap'
import CategoryCard from './CategoryCard'

class BodyMain extends React.Component {
  render() {
    const {data, category} = this.props
    const categories = data.allCategoriesYaml.edges[0].node.data
    const groups = data.categoryPages.group.reduce((obj, group) => {
      obj[group.fieldValue] = group
      return obj
    }, {})

    const pageCategory = category

    return (
      <Col md="8">
        <Container fluid>
          <Row>
            {categories.map(({category, name, icon}, i) => (
              !groups[category] ? null :
                <Col key={i} md="6">
                  <CategoryCard category={category} name={name} icon={icon} edges={groups[category].edges}/>
                </Col>
            ))
            }
          </Row>
        </Container>
      </Col>
    )
  }
}

BodyMain.propTypes = {
  data: PropTypes.shape({
    categoryPages: PropTypes.shape({
      edges: PropTypes.array,
    }),
    allCategoriesYaml: PropTypes.shape({
      edes: PropTypes.array
    })    
  }),
};

export default BodyMain