import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import {Card, CardHeader, ListGroup, ListGroupItem} from 'reactstrap'

class TagCard extends React.Component {
  render() {
    const {edges, tag, name} = this.props

    return (
      <Card>
        <CardHeader>
          <h3>{name}</h3>
        </CardHeader>
        <ListGroup flush>
          {edges &&
          edges.map(({node}, i) => (
            <ListGroupItem key={i}>
              <Link to={node.fields.slug}>
                {node.frontmatter.title}
              </Link>
            </ListGroupItem>
          ))}
        </ListGroup>
      </Card>
    )
  }
}

TagCard.propTypes = {
  edges: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      fields: PropTypes.shape({
        slug: PropTypes.string,
      }),
      frontmatter: PropTypes.shape({
        title: PropTypes.string,
      }),
    }),
  })),
}

export default TagCard